import tssEn from './en/tss.json'
import tssFr from './fr/tss.json'
import tssEs from './es/tss.json'
import tssAm from './am/tss.json'
import tssPt from './pt/tss.json'

import adminEn from './en/translation.json'
import adminFr from './fr/translation.json'
import adminEs from './es/translation.json'
import adminPt from './pt/translation.json'

export const tssRessources = {
    en: {
        tss: tssEn
    },
    fr: {
        tss: tssFr,
    },
    pt: {
        tss: tssPt
    },
    es: {
        tss: tssEs
    },
    am: {
        tss: tssAm
    }
}

export const adminResources = {
    en: {
        translation: adminEn
    },
    fr: {
        translation: adminFr
    },
    pt: {
        translation: adminPt
    },
    es: {
        translation: adminEs
    }
}